*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: $main-font;
}
