.header {
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  background-color: $primary;
  color: white;
  z-index: 10;

  &-logo {
    font-family: $logo-font;
    font-size: 40px;
    margin-bottom: 0;
    margin-right: 30px;
  }

  &-link_item {
    color: white;
    text-transform: uppercase;
    font-weight: $medium;
    font-size: 12px;
    margin: 0 30px;
  }

  &-icon_item {
    color: white;
    margin-left: 20px;
  }

  &-notification {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    right: -8px;
    text-align: center;

    &-text {
      font-size: 10px;
      font-weight: $medium;
      color: $primary;
      margin-bottom: 0;
      margin-top: 1px;
    }
  }
}