/////// RESPONSIVE

@mixin respond($breakpoint) {
  @if $breakpoint == XS {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @if $breakpoint == S {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }
  @if $breakpoint == M {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }
  @if $breakpoint == L {
    @media only screen and (max-width: 1199px) {
      @content;
    }
  }
  @if $breakpoint == XL {
    @media only screen and (max-width: 1399px) {
      @content;
    }
  }
}