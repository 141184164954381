@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@700&family=Inter:wght@400;500;600;700&display=swap');

.heading {
  color: $black;
  font-weight: $semi-bold;
  font-size: 16px;
  margin-bottom: 5px;

  &-card {
    font-size: 14px;
  }
}

.sub-heading {
  color: $gray;
  font-weight: $regular;
  font-size: 10px;
}

.contentText {
  color: $grayDark;
  font-weight: $regular;
  font-size: 12px;
  margin-bottom: 0;
}