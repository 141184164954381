.consultant {

  &-wrapper {
    width: 70%;
    margin: 0 auto;
    position: relative;

    @include respond(L) {
      width: 90%;
    }

    @include respond(M) {
      width: 100%;
    }
  }

  &-image-wrapper {
    width: 100%;
    height: 420px;
    border-radius: 8px;
    overflow: hidden;
  }
}