.ignore-header {
  margin-top: 95px;
  margin-bottom: 60px;
}

a {
  text-decoration: none;
}

.spinner-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overflow-scroll {
  overflow-x: scroll;
}

.overlay-dark {
  background-color: rgba(0,0,0,0.8);
}