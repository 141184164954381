.footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;

  &-copyright {
    margin-top: 3px;
  }

  &-items {
    margin: 0 30px;

    &-text {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: $regular;
      color: white;
    }
  }
}
