.productBook {
  height: 350px;
  cursor: pointer;

  &-image {
    height: 85%;
  }

  &-info {
    height: 15%;
    padding: 15px;

    &-heading {
      font-size: 14px;
      font-weight: $medium;
      color: $black;
      margin-bottom: 5px;
    }
  }
}