.radioButton {
  margin-top: 10px;

  input[type="radio"] {
    width: 20px;
    height: 20px;
    accent-color: $primary;
  }

  &-input {
    margin-right: 15px;
  }

  &-label {
    font-size: 16px;
    font-weight: $regular;
    color: $black;
  }
}