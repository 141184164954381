.imagePicker {
  width: 100%;
  height: 100%;
  position: relative;

  &-profile {
    width: 200px;
    height: 200px;
    position: relative;
    border: 1px solid $primary;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
  }

  &-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &-input-profile {
    display: none;
  }

  &-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-addIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &-cancel {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 10;
    cursor: pointer;
  }

  &-changePhoto {
    font-size: 16px;
    font-weight: $regular;
    text-decoration: underline;
    color: $primary;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
}
