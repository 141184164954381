.alimenti {
  

  &-quantity-container {
    padding: 10px 20px;
    border: 1px solid $grayLight;
    border-radius: 8px;
  } 

  &-quantity-text {
    font-size: 24px;
    color: $primary;
    margin-bottom: 0;
  }

  &-slider_track {
    top: 12px;
    height: 5px;
    background: $gray;
    border-radius: 20px;

  }
  
  &-slider_track-0 {
    background: $primary;
  }

  &-slider_thumb {
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12),
      0px 6px 13px rgba(0, 0, 0, 0.12);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    outline: none;
  }
}
