.link-nav {
  font-size: 14px;
  color: $gray;
  font-weight: $regular;
  padding: 0 20px;
  cursor: pointer;

  @include respond(L) {
    padding: 0 10px;
  }

  &:hover {
    color: $primary;
  }
}

.activeLink {
  color: $primary;
  border-bottom: 2px solid $primary;
}

.notActiveLink {
  color: $gray;
}
