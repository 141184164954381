.cookie {
  &-openButton {
    display: flex;
    justify-content: center;
  }

  &-modal-header {
    box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
    border-bottom: none;
    background-color: $primary;
    color: $white;
  }

  &-modal-dialog {
    max-width: 90%;
  }

  &-btn-close {
    color: $white;
    background-color: transparent;
    border: none;
    position: absolute;
    left: 10px;
  }

  &-title {
    font-size: 16px;
    font-weight: $medium;
    margin-bottom: 0;
  }
}

.modal-backdrop {
  z-index: -1;
}
