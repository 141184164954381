.button {
  width: 100%;
  height: 56px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: opacity 0.2s;
  padding: 0 30px;

  &:hover {
    opacity: 0.8;
  }

  &-text {
    font-size: 14px;
    font-weight: $semi-bold;
    margin: 0;
  }

  &-black {
    background-color: $black;
  }

  &-primary {
    background-color: $primary;
  }

  &-outline {
    border: 1px solid $primary;
    color: $primary;
    background-color: white;
  }
}