.parametri {
  &-progress {
    &-wrapper {
      width: 110px;
      margin: 0 20px;
    }

    &-heading {
      font-weight: $bold;
      text-transform: uppercase;
      font-size: 10px;
    }

    &-details {
      margin-top: 10px;
    }

    &-subtext {
      font-weight: $medium;
      font-size: 10px;
      color: $gray;
    }
  }

  &-items {
    &-wrapper {
      width: 100px;
      padding: 10px;
      border: 2px solid #f5f5f5;
      border-radius: 20px;
    }

    &-heading {
      font-size: 12px;
      color: $black;
      font-weight: $bold;
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    &-value {
      font-size: 20px;
      color: $primary;
      font-weight: $semi-bold;
      margin-bottom: 3px;
    }

    &-changes {
      font-size: 12px;
      color: $green;
      font-weight: $bold;
      margin-bottom: 0;
    }
  }

  &-image-wrapper {
    width: 180px;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
  }
}
