.login {

  &-bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  &-wrapper {
    width: 30%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @include respond(L) {
      width: 50%;
    }

    @include respond(M) {
      width: 60%;
    }
  }

  &-logo {
    font-family: $logo-font;
    font-weight: $bold;
    font-size: 60px;
    color: white;
    margin-bottom: 30px;
  }

  &-description {
    font-weight: $regular;
    color: white;
    font-size: 12px;
    margin-bottom: 30px;
  }

  &-help {
    text-align: left;
    color: $white;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    accent-color: $primary;
  }

  &-checkbox {
    margin-right: 10px;

    &-label {
      font-size: 14px;
      color: $white;
      margin-bottom: 0;
    }
  }
}