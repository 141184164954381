.label {
  //margin: 10px;
  padding: 3px 10px;
  text-align: center;
  border-radius: 20px;

  &-text {
    font-size: 10px;
    color: $white;
    font-weight: $semi-bold;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}