.informationCard {
  width: 100%;
  height: 65px;
  box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
  border-radius: 8px;
  padding: 8px 15px;
  margin-top: 8px;

  &-placeholder {
    font-size: 14px;
    font-weight: $regular;
    margin-bottom: 3px;
    color: $primaryLight;
  }

  &-info {
    font-size: 16px;
    font-weight: $regular;
    margin-bottom: 3px;
    color: $black;
  }
}
