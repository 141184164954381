.cookiePopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: end;

  &-wrapper {
    width: 500px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 32px 34px rgba(28, 27, 32, 0.198973);
    padding: 20px;
    margin-bottom: 20px;
  }

  &-title {
    font-size: 14px;
    font-weight: $regular;
  }
}
