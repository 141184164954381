.appOnly {
  &-wrapper {
    width: 90%;
    margin: 0 auto;
    height: 100%;
  }

  &-image-wrapper {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
    margin: 20px auto;
  }

}