.faqCard {
  padding: 15px;
  cursor: pointer;

  &-heading {
    margin-bottom: 0;
    color: $black;
    font-size: 16px;
    font-weight: $semi-bold;
  }

  &-description {
    margin-bottom: 0;
    color: $gray;
    font-size: 12px;
    font-weight: $regular;
  }
}