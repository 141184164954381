.courseDetails {

  &-wrapper {
    width: 60%;
    margin: 0 auto;

    @include respond(L) {
      width: 80%;
    }

    @include respond(M) {
      width: 90%;
    }
  }

  &-nav {
    font-size: 12px;
    font-weight: $regular;
    color: $primary;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 0;
  }

  &-info-price {
    font-size: 26px;
    font-weight: $medium;
    color: $black;
    margin-bottom: 0;
    text-align: right;
  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &-left {
      left: -40px;
    }

    &-right {
      right: -40px;
    }
  }

  &-items {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 5.46128px 20.4798px rgba(181, 181, 181, 0.25);
    border-radius: 8px;
    width: 100px;
    padding: 10px;
    text-align: center;
    margin-right: 8px;
    background-color: $green;

    &-text {
      font-size: 10px;
      font-weight: $bold;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &-content {
      font-size: 14px;
      font-weight: $semi-bold;
      color: $white;
      margin-bottom: 0;
    }
  }
}