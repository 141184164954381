.notifications {

  &-wrapper {
    width: 40%;
    margin: 0 auto;

    @include respond(L) {
      width: 50%;
    }

    @include respond(M) {
      width: 70%;
    }
  }
}

.notificationCard {

  &-heading {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: $medium;
    color: $black;

    &-active {
      color: $primary
    }
  }

  &-text {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: $regular;
    color: $black;

    &-active {
      color: $primary;
    }
  }

  &-time {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: $regular;
    color: $primary;
    text-transform: uppercase;
  }
}