.ingredient {
  border: 1px solid $grayLight;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 15px;
  width: 100%;
  

  &-name {
    font-size: 16px;
    font-weight: $regular;
    color: $black;
    margin-bottom: 0;
  }

  &-weight {
    color: $gray;
  }

  &-macros {
    font-size: 10px;
    font-weight: $bold;
    color: $gray;
    text-transform: uppercase;
    margin-bottom: 0;
  }

}
