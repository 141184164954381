.dishCard {
  width: 200px;
  height: 230px;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 2px 15px rgba(181, 181, 181, 0.25);
  border-radius: 8px;
  margin: 5px;
  flex-shrink: 0; 

  &-img-wrapper {
    height: 75%;
    width: 100%;
    background-color: $primaryLightShadow;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &-info {
    padding-top: 10px;

    &-name {
      font-size: 12px;
      font-weight: $regular;
      color: $black;
      margin-bottom: 4px;
    }
  }

  &-item {
    &-name {
      font-size: 10px;
      color: $gray;
      font-weight: $regular;
      text-transform: uppercase;
      margin: 0 2px;
    }
  }
}
