.check {
  .modal {
    z-index: 99;
  }

  &-openButton {
    display: flex;
    justify-content: center;
  }

  &-modal-header {
    box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
    border-bottom: none;
    background-color: $primary;
    color: $white;
  }

  &-modal-dialog {
    max-width: 90%;
  }

  &-btn-close {
    color: $white;
    background-color: transparent;
    border: none;
    position: absolute;
    left: 10px;
  }

  &-title {
    font-size: 16px;
    font-weight: $medium;
    margin-bottom: 0;
  }

  &-body-wrapper {
    width: 70%;
    margin: 0 auto;
  }

  &-image-wrapper {
    width: "100%";
    height: 350px;
    overflow: hidden;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
  }
}
