.dishDetails {
  .modal {
    z-index: 99;
  }

  &-openButton {
    display: flex;
    justify-content: center;
  }

  &-modal-header {
    box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
    border-bottom: none;
    background-color: $primary;
    color: $white;
  }

  &-modal-dialog {
    max-width: 90%;
  }

  &-btn-close {
    color: $white;
    background-color: transparent;
    border: none;
    position: absolute;
    left: 10px;
  }

  &-title {
    font-size: 16px;
    font-weight: $medium;
    margin-bottom: 0;
  }

  &-item {
    width: 105px;
    height: 55px;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
    border-radius: 8px;
    padding: 8px 0;
    margin-right: 5px;

    &-text {
      font-weight: $bold;
      font-size: 10px;
      text-transform: uppercase;
      color: $black;
      margin-bottom: 2px;
    }

    &-quantity {
      font-weight: $semi-bold;
      font-size: 14px;
      color: $primary;
    }
  }

  &-image-wrapper {
    overflow: hidden;
    width: 100%;
    height: 600px;
    border-radius: 8px;

    @include respond(L) {
      height: 400px;
    }
  }
}
