.prodottiExtra {
  &-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  &-nav {
    width: 150px;
    padding: 0 20px;
    cursor: pointer;

    &-active {
      border-bottom: 2px solid $primary;
    }

    &-text {
      font-size: 14px;
      font-weight: $regular;
      color: $gray;
      margin-bottom: 5px;
      text-align: center;

      &-active {
        color: $primary;
      }
    }
  }
}
