.changePassword {

.modal {
  z-index: 99;
}

  &-openButton {
    display: flex;
    justify-content: center;
  }

  &-modal-header {
    box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
    border-bottom: none;
    background-color: $primary;
    color: $white;
  }

  &-modal-dialog {
    max-width: 90%;
  }

  &-btn-close {
    color: $white;
    background-color: transparent;
    border: none;
    position: absolute;
    left: 10px;
  }

  &-title {
    font-size: 16px;
    font-weight: $medium;
    margin-bottom: 0;
  }

  &-wrapper {
    width: 40%;
    margin: 0 auto;

    @include respond(L) {
      width: 60%;
    }

    @include respond(M) {
      width: 80%;
    }
  }
}