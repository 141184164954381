.closedContent {
  width: 30%;
  margin: 0 auto;

  &-content {
    font-size: 12px;
    font-weight: $regular;
    color: $black;
  }

  &-button-wrapper {

  }
}