.legalModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-origin: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &-wrapper {
    width: 70%;
    height: 90%;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 32px 34px rgba(28, 27, 32, 0.198973);
    padding: 20px 40px;
    overflow-y: scroll;
    background-color: $primaryLightShadow;

    @include respond(M) {
      width: 90%;
    }
  }

  &-card {
    background: $white;
    box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.35);
    border-radius: 8px;
    padding: 20px 40px;
    margin-bottom: 20px;
  }
}

.more-button {
  margin-bottom: 0;

  & a {
    font-size: 12px;
    font-weight: 400;
    color: $primary;
    text-decoration: underline;
  }
}