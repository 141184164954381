.anamnesi {
  
  &-wrapper {
    width: 60%;
    margin: 0 auto;
  }

  &-question {
    text-align: center;

    &-text {
      font-size: 16px;
      color: $primary;
      font-weight: $medium;
    }
  }

  &-response {


    &-text {
      font-size: 14px;
      font-weight: $regular;
      color: $black;
      margin-bottom: 0;
    }

    &-input {
      height: 40px;
      width: 100%;
      border: none;
      border-bottom: 1px solid $gray;

      &::placeholder {
        color: $gray;
      }

      &:focus {
        outline: none;
      }
    }
  }
}