.profile {

  &-item {
    padding: 25px;
    cursor: pointer;

    @include respond(L) {
      padding: 15px;
    }

    &-active {
      background-color: $primary;
    }
  }

  &-item-text {
    font-size: 14px;
    font-weight: $semi-bold;
    color: $gray;
    margin-bottom: 0;

    &-active {
      color: $white;
    }
  }
}