.donePopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    background-color: $green;
    padding: 10px;
    border: 2px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
