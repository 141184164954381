.selectPlate {
  &-nav {
    &-items {
      font-size: 14px;
      color: $gray;
      font-weight: $regular;
      padding: 0 40px;
      cursor: pointer;
    }

    &-itemsActive {
      border-bottom: 2px solid $primary;
      color: $primary;
    }
  }

  &-modal-header {
    box-shadow: 0px 4px 15px rgba(181, 181, 181, 0.25);
    border-bottom: none;
  }

  &-modal-dialog {
    max-width: 90%;
  }

}

.ricette {

  &-wrapper {
    //flex-wrap: wrap;
    overflow-x: scroll;
  }

  &-container {
    flex-wrap: wrap;
  }

}

.alimenti {

  &_search-container {
    width: 100%;
    border: 1px solid $primaryLight;
    border-radius: 8px;
    padding: 10px;
  }

  &_input {
    border: none;
    color: $primary;

    &::placeholder {
      color: $primaryLightShadow
    }

    &:focus-visible {
      outline: none;
    }
  }
}